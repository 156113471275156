<template>
    <v-container>
        <div style="margin-bottom: 20px;">
            <span>{{ message }}</span>
        </div>
        <div style="margin-bottom: 20px;">
            <v-btn @click="cleanCache()">ניקוי זיכרון זמני</v-btn>
        </div>

        <div style="margin-bottom: 20px;">
            <span>הוסף שם עיר חדשה</span>
            <div style="margin-bottom: 20px; display: flex;">
                <v-text-field style="max-width: 300px;" v-model="newCity" label="שם העיר בעברית"></v-text-field>
                <v-btn @click="addCityOrCountry('city')">הוסף</v-btn>
            </div>
        </div>
        <div style="margin-bottom: 20px;">
            <span>הוסף שם מדינה חדשה</span>
            <div style="margin-bottom: 20px; display: flex;">
                <v-text-field style="max-width: 300px;" v-model="newCountry" label="שם המדינה בעברית"></v-text-field>
                <v-btn @click="addCityOrCountry('country')">הוסף</v-btn>
            </div>
        </div>
    </v-container>
</template>
<script>
import AddRestaurant from '@/components/admin/AddRestaurant.vue'
import EditRestaurant from '@/components/admin/EditRestaurant'
import ApiServices from '@/services/api.service'

export default {
    components: {
        AddRestaurant,
        EditRestaurant,
    },
    data() {
        return {
            addRestaurant: false,
            editRestaurant: false,
            message: "",
            activeTab: 0,
            adminSettings: true,
            newCity: "",
            newCountry: "",
            tabs: ["הוספת מסעדה חדשה", "עריכת מסעדה", "הגדרות מנהל"]
        }
    },
    methods: {
        async cleanCache() {
            try {
                let api = process.env.VUE_APP_BASE_URL + "/admin/settings_clean_cache";
                const token = localStorage.getItem("token");
                const res = await fetch(api, ApiServices.requestOptions("POST", "", token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.message = "שגיאה!";
                    this.hideMessage();
                } else if (res.status === 200) {
                    this.message = "בוצע בהצלחה";
                    this.hideMessage();
                }
            } catch (error) {
                this.message = error.message;
                this.hideMessage();
            }
        },
      async addCityOrCountry(type) {
            try {
                let name = this.newCity;
                if (type === "country") {
                    name = this.newCountry;
                }
                let api = process.env.VUE_APP_BASE_URL + "/admin/settings_add_city_or_country_name";
                const token = localStorage.getItem("token");
                const jsonBody = JSON.stringify({ nameHe: name, type });
                const res = await fetch(api, ApiServices.requestOptions("POST", jsonBody, token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.message = "שגיאה!";
                    this.hideMessage();
                } else if (res.status === 200) {
                    this.message = "בוצע בהצלחה";
                    this.newCity = "";
                    this.newCountry = "";
                    this.hideMessage();
                }
            } catch (error) {
                this.message = error.message;
            }
        },
        hideMessage() {
            setTimeout(() => {
                this.message = "";
            }, 4000);
        }
    },
    mounted() {
    },
}
</script>
<style scoped>
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    margin-right: 5px;
    margin-left: 5px;
}
</style>