<template>
    <div class="center-container">
        <v-card class="center-card" elevation="4" style="margin-bottom: 100px;">
            <div class="d-flex justify-end pt-3 pl-3">
                <span class="text-center flex-grow-1"
                    style="font-weight: bold; font-size: 30px; font-family: Times, serif; color: #062a4e;">הוספת מסעדה
                </span>
            </div>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row v-if="itemEdited.zone_web">
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.name" label="שם" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.address" label="רחוב" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-autocomplete :items="citiesItems" v-model="itemEdited.city_web" label="עיר"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-autocomplete :items="countriesItems" v-model="itemEdited.country_web"
                                    label="מדינה"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-autocomplete :items="zonesItems" v-model="itemEdited.zone_web" label="אזור"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.website" label="אתר"></v-text-field>
                            </v-col><v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.phone" @input="filterNumeric"
                                    label="טלפון"></v-text-field>
                            </v-col><v-col cols="12" sm="6">
                                <v-autocomplete :items="categoriesItems" v-model="itemEdited.category_web" label="קטגוריה"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete :items="subCategoriesItems" multiple v-model="itemEdited.sub_category_web"
                                    label="תת קטגוריה"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="typesItems" multiple v-model="itemEdited.type_web" label="סוג"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.details" label="מידע על המסעדה"></v-text-field>
                            </v-col>
                            <span style="font-weight: bold; font-size: large;">כשרות</span>
                            <v-col cols="12" sm="4">
                                <v-autocomplete :items="kashrutItems" v-model="itemEdited.kashrut.kashrut_name_web"
                                    :rules="[v => !!v || 'שדה חובה']" required label="כשרות"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="4"
                                v-if="itemEdited.kashrut.kashrut_name_web === objKashruyot['badatz_local']">
                                <v-text-field v-model="itemEdited.kashrut.local_kashrut_name"
                                    label="כשרות מקומית"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="itemEdited.kashrut.kashrut_details"
                                    label="פרטים נוספים על הכשרות"></v-text-field>
                            </v-col>
                            <span style="font-weight: bold; font-size: large;">שעות פתיחה</span>
                            <v-col cols="auto" sm="auto">
                                <span>יום א'</span>
                                <v-text-field v-model="itemEdited.open_hours.sunday.open" type="time"
                                    label="פתיחה"></v-text-field>
                                <v-text-field v-model="itemEdited.open_hours.sunday.close" type="time"
                                    label="שעות סגירה"></v-text-field>
                            </v-col>
                            <v-col cols="auto" sm="auto">
                                <span>יום ב'</span>
                                <v-text-field v-model="itemEdited.open_hours.monday.open" type="time"
                                    label="פתיחה"></v-text-field>
                                <v-text-field v-model="itemEdited.open_hours.monday.close" type="time"
                                    label="שעות סגירה"></v-text-field>
                            </v-col>
                            <v-col cols="auto" sm="auto">
                                <span>יום ג'</span>
                                <v-text-field v-model="itemEdited.open_hours.tuesday.open" type="time"
                                    label="פתיחה"></v-text-field>
                                <v-text-field v-model="itemEdited.open_hours.tuesday.close" type="time"
                                    label="שעות סגירה"></v-text-field>
                            </v-col>
                            <v-col cols="auto" sm="auto">
                                <span>יום ד'</span>
                                <v-text-field v-model="itemEdited.open_hours.wednesday.open" type="time"
                                    label="פתיחה"></v-text-field>
                                <v-text-field v-model="itemEdited.open_hours.wednesday.close" type="time"
                                    label="שעות סגירה"></v-text-field>
                            </v-col>
                            <v-col cols="auto" sm="auto">
                                <span>יום ה'</span>
                                <v-text-field v-model="itemEdited.open_hours.thursday.open" type="time"
                                    label="פתיחה"></v-text-field>
                                <v-text-field v-model="itemEdited.open_hours.thursday.close" type="time"
                                    label="שעות סגירה"></v-text-field>
                            </v-col>
                            <v-col cols="auto" sm="auto">
                                <span>יום ו'</span>
                                <v-text-field v-model="itemEdited.open_hours.friday.open" type="time"
                                    label="פתיחה"></v-text-field>
                                <v-text-field v-model="itemEdited.open_hours.friday.close" type="time"
                                    label="שעות סגירה"></v-text-field>
                            </v-col>
                            <v-col cols="auto" sm="auto">
                                <span>מוצ"ש</span>
                                <v-text-field v-model="itemEdited.open_hours.saturday.open" type="time"
                                    label="פתיחה"></v-text-field>
                                <v-text-field v-model="itemEdited.open_hours.saturday.close" type="time"
                                    label="שעות סגירה"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox v-model="itemEdited.open_hours.only_weekend" :label="weekendTitle"
                                    style="margin-bottom: 0px !important; margin-top: 0px !important;">
                                </v-checkbox>
                            </v-col>
                            <span style="font-weight: bold; font-size: large;">מיקום</span>
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="itemEdited.location.latitude" label="קו אורך"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="itemEdited.location.longitude" label="קו רוחב"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-btn @click="chooseDeviceLocation()" rounded>או בחר מיקום שלי</v-btn>
                            </v-col>
                            <v-col cols="12">
                                <!-- Search Field -->
                                <GmapPlaceInput @place_changed="setPlace" ref="gmapInput" />
                                <!-- Google Maps -->
                                <GmapMap ref="gmapRef" :center="center" :zoom="12" style="width: 100%; height: 400px"
                                    @click="mapClicked">
                                    <GmapMarker v-if="marker.position.lat && marker.position.lng"
                                        :position="marker.position" @click="markerClicked"></GmapMarker>
                                </GmapMap>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center" style="margin-top: 20px;">
                                <v-btn color="#062a4e" dark @click="addRestaurant()" rounded>הוסף מסעדה</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>

                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </div>
</template>
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar'
import Constants from '@/util/GetConstant'

export default {
    components: {
        SnackBar
    },
    data() {
        return {
            progressShow: false,
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            itemEdited: {},
            objKashruyot: {},
            objTypes: {},
            objCategories: {},
            objSubCategories: {},
            objZones: {},
            objCountries: {},
            objCities: {},
            weekendTitle: "פתוח רק בסופ\"ש",
            center: { lat: 32.0879976, lng: 34.8384455 },
            marker: {
                position: { lat: 32.0879976, lng: 34.8384455 }
            }

        }
    },
    methods: {

        async addRestaurant() {
            if (this.$refs.form.validate()) {

                try {
                    this.progressShow = true;
                    let token = localStorage.getItem("token");
                    const restaurantJSON = JSON.stringify({ rest: this.itemEdited })
                    let api = process.env.VUE_APP_BASE_URL + `/admin/add_restaurant`;
                    const res = await fetch(api, ApiServices.requestOptions("POST", restaurantJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("שגיאה בהוספת מסעדה : " + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.initializeEmptyObj();
                        this.showSnackBar("המסעדה נוספה בהצלחה!", "green");

                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error save new restaurant: " + error, "red");
                }
            }
        },
        updateMarkerPosition() {
            const latitude = parseFloat(this.itemEdited.location.latitude);
            const longitude = parseFloat(this.itemEdited.location.longitude);

            // Ensure the latitude and longitude are valid numbers
            if (isFinite(latitude) && isFinite(longitude)) {
                // Update marker position with valid latitude and longitude
                this.marker.position = {
                    lat: latitude,
                    lng: longitude
                };

                // Optionally, move the map to center on the new marker position
                this.$refs.gmapRef.$mapObject.panTo(this.marker.position);
            } else {
                console.error("Invalid latitude or longitude values:", latitude, longitude);
            }
        },
        chooseDeviceLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.showPosition(position);
                        { true }
                    },
                    error => {
                        this.showSnackBar("שגיאה בקבלת המיקום: יש להפעיל את המיקום בלחיצה על האייקון למעלה בשורת הכתובת", "red");
                    }
                );
            } else {
                this.showSnackBar("Geolocation is not supported", "red");
            }
        },
        showPosition(position) {
            this.itemEdited.location.latitude = position.coords.latitude;
            this.itemEdited.location.longitude = position.coords.longitude;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        async getConstants() {
            const constants = await Constants.getConstant();
            if (!constants) {
                this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
            }
            this.objKashruyot = constants.kashruyot
            this.objTypes = constants.types;
            this.objCategories = constants.categories;
            this.objSubCategories = constants.sub_categories;
            this.objZones = constants.zones
            this.objCountries = constants.countries
            this.objCities = constants.cities
        },
        // async getAutoComplete() {
        //     const constants = await Constants.getSearchAutoCompleteList();
        //     if (!constants) {
        //         this.showSnackBar("שגיאה: יותר מדי כניסות מהכתובת הזאת, נסה שוב מאוחר יותר", "red");
        //     }
        //     this.arrayCountries = constants.map(constant => constant.country);
        //     this.arrayCities = constants.map(constant => constant.city);
        // },
        initializeEmptyObj() {
            this.itemEdited = {
                name: "",
                address: "",
                city: "",
                country: "",
                zone: "",
                zone_web: "israel",
                type: [],
                type_web: [],
                phone: "",
                website: "",
                category: "",
                category_web: "",
                sub_category: [],
                sub_category_web: [],
                details: "",
                pictures: [],
                logo: null,
                kashrut: { kashrut_name: "", kashrut_name_web: "", local_kashrut_name: "", kashrut_details: "", certificate: null },
                open_hours: {
                    sunday: { open: "", close: "" }, monday: { open: "", close: "" }, tuesday: { open: "", close: "" },
                    wednesday: { open: "", close: "" }, thursday: { open: "", close: "" }, friday: { open: "", close: "" }, saturday: { open: "", close: "" },
                    only_weekend: false,
                },
                location: { latitude: "", longitude: "" }
            }
        },
        setPlace(place) {
            this.marker.position = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };
            this.center = this.marker.position;
        },
        markerClicked(event) {
            alert(`Marker clicked! Latitude: ${event.latLng.lat()} Longitude: ${event.latLng.lng()}`);
        },
        mapClicked(event) {

            this.marker.position = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            };

            this.itemEdited.location.latitude = event.latLng.lat();
            this.itemEdited.location.longitude = event.latLng.lng();
        },
        filterNumeric(value) {
            console.log(value);
            // Replace non-numeric characters with an empty string
            this.itemEdited.phone = value.replace(/\D+/g, '');
        }
    },
    computed: {
        kashrutItems() {
            return Object.entries(this.objKashruyot).map(([key, value]) => ({
                value: key, // You can use the key as the value
                text: value[this.$i18n.locale] // or value.en for English
            }));
        },
        zonesItems() {
            return Object.entries(this.objZones).map(([key, value]) => ({
                value: key, // You can use the key as the value
                text: value[this.$i18n.locale] // or value.en for English
            }));
        },
        typesItems() {
            return Object.entries(this.objTypes).map(([key, value]) => ({
                value: key, // You can use the key as the value
                text: value[this.$i18n.locale] // or value.en for English
            }));
        },
        categoriesItems() {
            return Object.entries(this.objCategories).map(([key, value]) => ({
                value: key, // You can use the key as the value
                text: value[this.$i18n.locale] // or value.en for English
            }));
        },
        subCategoriesItems() {
            return Object.entries(this.objSubCategories).map(([key, value]) => ({
                value: key, // You can use the key as the value
                text: value[this.$i18n.locale] // or value.en for English
            }));
        },
        countriesItems() {
            return Object.entries(this.objCountries).map(([key, value]) => ({
                value: key, // You can use the key as the value
                text: value[this.$i18n.locale] // or value.en for English
            }));
        },
        citiesItems() {
            return Object.entries(this.objCities).map(([key, value]) => ({
                value: key, // You can use the key as the value
                text: value[this.$i18n.locale] // or value.en for English
            }));
        },
    },
    mounted() {
        this.getConstants();
        // this.getAutoComplete();
        this.initializeEmptyObj();
        // change maps input style
        this.$nextTick(() => {
            const input = this.$refs.gmapInput.$el.querySelector('input');
            if (input) {
                input.style.backgroundColor = '#f6f6f6';
                input.style.padding = '10px';
                input.style.border = '1px solid rgb(201, 191, 191)';
                input.style.width = '300px';
                input.style.maxWidth = '100%';
            }
        });
    },
    watch: {
        'itemEdited.location.latitude': function (newLat) {
            console.log(333);
            this.updateMarkerPosition();
        },
        'itemEdited.location.longitude': function (newLng) {
            console.log(333);
            this.updateMarkerPosition();
        }
    }
}
</script>
<style scoped>
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-right: 5px;
    margin-left: 5px;
}

.center-card {
    max-width: 900px;
    width: 100%;
    padding-bottom: 40px;
    margin-left: 5px;
    margin-right: 5px;
}

.v-file-input .v-label {
    align-self: center;
}


#fileInput {
    display: none;
}

/* Style the label as a square */
.label1 {
    display: block;
    width: 100px;
    /* Adjust as needed */
    height: 100px;
    /* Adjust as needed */
    background-color: #f4f4f4;
    border: 2px dashed #333;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Arial, sans-serif;
}

label:hover {
    background-color: #ddd;
}</style>